import React from "react"
import Header from "../components/header";
import {getImage} from "gatsby-plugin-image";
import {convertToBgImage} from "gbimage-bridge";
import BackgroundImage from 'gatsby-background-image';
import {graphql, useStaticQuery} from "gatsby";
import ActionButton from "../components/action-button";
import Footer from "../components/footer";
import ReactPlayer from 'react-player/vimeo';

export default function IndexPage() {

    const { abstractBg, blueBg, yellowBg } = useStaticQuery(
        graphql`
        query {
            abstractBg: file(relativePath: { eq: "abstract-blue-yellow.jpeg" }) {
                childImageSharp {
                    gatsbyImageData(
                      breakpoints: [2500]
                      quality: 100
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
            blueBg: file(relativePath: { eq: "cyan-blue.png" }) {
                childImageSharp {
                    gatsbyImageData(
                      breakpoints: [2500]
                      quality: 100
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
            yellowBg: file(relativePath: { eq: "cyan-yellow.png" }) {
                childImageSharp {
                    gatsbyImageData(
                      breakpoints: [2500]
                      quality: 100
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
        }
    `
    );

    const bgAbstractImage = convertToBgImage(getImage(abstractBg));
    const bgBlueImage = convertToBgImage(getImage(blueBg));
    const bgYellowImage = convertToBgImage(getImage(yellowBg));
    
    return (
        <main className="w-full">
            <BackgroundImage Tag="section" className="relative min-h-screen w-full" {...bgAbstractImage}>
                <div className="absolute w-full">
                    <Header/>
                </div>
                <div className="flex flex-col justify-center h-screen max-w-7xl mx-auto">
                    <div className="lg:px-10 px-6 py-5 leading-tight font-poppins text-white text-4xl lg:text-6xl max-w-2xl">La forma más fácil de contratar a los mejores talentos</div>
                    <div className="lg:px-10 px-6 py-5 leading-loose font-poppins text-white max-w-xl">Software de seguimiento de candidatos + Tu Headhunter personal = Más valor</div>
                    <div className="lg:px-10 px-6 py-5"><ActionButton mode="light" to="/contact">¡Habla con nosotros!</ActionButton></div>
                </div>    
            </BackgroundImage>
            <section className="w-full min-h-3-4-screen flex flex-col justify-center items-center">
                <div className="py-4 px-8 text-center font-poppins leading-tight text-3xl lg:text-5xl max-w-2xl">Tu Headhunter personal con el pipeline de recursos humanos más eficiente: Find IT</div>
                <div className="py-4 px-8 text-center font-poppins max-w-2xl">Tenemos headhunters especializados para tus vacantes y un software de seguimiento de candidatos listo para apoyarte.</div>
                <div className="py-5 text-center"><ActionButton to="/about">¡Conócenos!</ActionButton></div>
            </section>
            <BackgroundImage Tag="section" className="w-full min-h-3-4-screen bg-black flex items-center justify-center" {...bgBlueImage}>
                <div className="max-w-7xl grid grid-cols-1 lg:grid-cols-2 justify-center items-center">
                    <div className="p-8 max-w-xl">
                        <div className="my-4 font-poppins text-white text-3xl lg:text-5xl">Nuestra Misión</div>
                        <div className="my-8 font-poppins text-white leading-loose">
                            La misión de nuestros headhunters especializados en diversas ramas del mundo de las TI, es reclutar a los mejores candidatos para ti.
                            Nuestro Pipeline de recursos humanos te ofrece un proceso de contratación fácil, no sólo para tus candidatos, sino también para tu propio equipo.
                        </div>
                        <div className="my-10 text-center">
                            <ActionButton to="/about" mode="light">¿Por qué Find IT?</ActionButton>
                        </div>
                    </div>
                    <div className="p-8 w-full text-white">
                        <div style={{paddingTop: "56.25%", position:"relative"}}>
                            <ReactPlayer light playing url="https://player.vimeo.com/video/578005985" style={{position:"absolute", top:0, left:0}} width="100%" height="100%" />
                        </div>
                    </div>
                </div>
            </BackgroundImage>
            <section className="w-full min-h-3-4-screen flex flex-col justify-center items-center">
                <div className="py-4 px-8 text-center font-poppins leading-tight text-3xl lg:text-5xl">¡Simplifica tu Reclutamiento!</div>
                <div className="py-4 px-8 text-center font-poppins lg:max-w-2xl">
                    A cada vacante se le asignará un headhunter especializado y tu podrás formar parte del proceso de contratación a través de nuestro Software
                    de seguimiento de candidatos: Find It.
                    <br /><br/>
                    Rápido, sencillo y eficaz.
                </div>
            </section>
            <BackgroundImage Tag="section" className="w-full min-h-3-4-screen bg-black flex flex-col justify-center items-center" {...bgYellowImage}>
                <div className="py-4 px-8 text-center font-poppins text-white leading-tight text-3xl lg:text-5xl">¡Comencemos!</div>
                <div className="py-4 px-8 text-center font-poppins text-white max-w-2xl">
                    Agiliza tu proceso de contratación con nosotros. Tus búsquedas no pueden esperar.
                </div>
                <div className="py-8">
                    <ActionButton to="/lets-start" mode="light">Contáctanos</ActionButton>
                </div>
            </BackgroundImage>
            <Footer />
        </main>
    )
}
